.slider {
  position: fixed;
  top: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition-duration: 1s;
  transition-property: all;
  z-index: 90;
}
.evo_screenshooting .slider {
  transition-duration: 0s;
}
.slider.open {
  opacity: 1;
  left: 0px !important;
}
.slider:focus, .slider:focus-within {
  outline: 1px solid #007681;
}
.slider a.close {
  position: absolute;
  right: 24px;
  top: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: left;
  text-indent: -9999px;
  background-color: #007681;
  cursor: pointer;
}
.slider a.close::before, .slider a.close::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 11px;
  width: 16px;
  height: 2px;
  background-color: white;
}
.slider a.close::before {
  transform: rotate(45deg);
}
.slider a.close::after {
  transform: rotate(-45deg);
}
.slider a.close:hover {
  background-color: #003A4E;
}
.slider .slider-wrapper {
  padding: 24px 24px 0 24px;
}
.slider .slider-wrapper h3 {
  margin: 0 0 1rem 0;
  color: #003A4E;
  font-size: 25px;
  line-height: 32px;
}
.slider .slider-wrapper h3 + p {
  margin-top: 0;
}
.slider .slider-wrapper h3:has(> p) {
  margin-bottom: 0;
}
.slider .slider-wrapper p {
  margin: 1rem 0;
  color: #4C5970;
}
.slider .slider-wrapper p:first-child {
  margin-top: 0;
}
.slider .slider-wrapper p:last-child {
  margin-bottom: 0;
}
.slider .slider-wrapper .button {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .slider {
    left: -450px;
    width: 450px;
  }
  .slider .slider-wrapper {
    padding-left: 56px;
  }
}

main section.module-hero {
  position: relative;
  min-height: 300px;
  background-color: #003A4E;
  background-image: url("/assets/img/heros/home-hero-m.22d7dd87.jpg");
  background-size: 428px;
  background-position: center top;
  background-repeat: no-repeat;
}
html[data-webp=true] main section.module-hero {
  background-image: url("/assets/img/heros/home-hero-m.8ac62581.webp");
}
@media print, (min-resolution: 192dpi) {
  main section.module-hero {
    background-image: url("/assets/img/heros/home-hero-m-2x.9f3bc9f4.jpg");
  }
  html[data-webp=true] main section.module-hero {
    background-image: url("/assets/img/heros/home-hero-m-2x.44c1ccfb.webp");
  }
}
@media screen and (min-width: 429px) and (max-width: 767.9px) {
  main section.module-hero {
    background-image: url("/assets/img/heros/home-hero-m-768.edfccf9e.jpg");
    background-size: 768px;
  }
  html[data-webp=true] main section.module-hero {
    background-image: url("/assets/img/heros/home-hero-m-768.303c9a5f.webp");
  }
}
@media screen and (min-width: 429px) and (max-width: 767.9px) and (min-resolution: 192dpi) {
  main section.module-hero {
    background-image: url("/assets/img/heros/home-hero-m-768-2x.a626c5b5.jpg");
  }
  html[data-webp=true] main section.module-hero {
    background-image: url("/assets/img/heros/home-hero-m-768-2x.27d81157.webp");
  }
}
main section.module-hero .photo-note {
  top: -8px;
}
main section.module-hero .section-wrapper {
  text-align: center;
  color: white;
  padding-top: 20px;
}
main section.module-hero .section-wrapper:first-child {
  padding: 100px 0 0 0;
}
main section.module-hero .section-wrapper h1 {
  color: #ffffff;
  text-align: center;
  text-shadow: 0px 0px 10px #000;
}
main section.module-hero .section-wrapper h1 span:nth-child(1) {
  display: block;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
}
main section.module-hero .section-wrapper h1 span:nth-child(2) {
  display: block;
  font-family: "Zilla Slab", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
}
main section.module-hero .section-wrapper ul {
  margin: 0;
  padding: 0;
}
main section.module-hero .section-wrapper ul li {
  display: block;
  max-width: 300px;
  margin: 1rem auto 0 auto;
}
main section.module-hero .section-wrapper ul li:first-child {
  margin-top: 0;
}
main section.module-hero .section-wrapper ul li a {
  display: block;
  color: #003A4E;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding: 24px;
  border-radius: 24px;
  list-style-type: none;
  background-color: white;
  text-decoration: none;
}
main section.module-hero .section-wrapper ul li a span {
  position: relative;
  display: block;
  padding: 106px 0 48px 0;
}
main section.module-hero .section-wrapper ul li a span::before {
  content: "";
  position: absolute;
  left: calc(50% - 45px);
  top: 0;
  display: block;
  width: 90px;
  height: 92px;
  background-image: var(--icon-placeholder);
  background-size: 90px 92px;
  background-repeat: no-repeat;
}
main section.module-hero .section-wrapper ul li a span::after {
  content: "";
  position: absolute;
  left: calc(50% - 20px);
  bottom: 0;
  display: block;
  width: 40px;
  height: 40px;
  background-image: var(--circle-arrow-right);
  background-size: contain;
  background-repeat: no-repeat;
  filter: var(--circle-arrow-right-shadow);
}
main section.module-hero .section-wrapper ul li.liver a span::before {
  background-image: url("/assets/img/icons/icon-liver.1278a4dc.svg");
}
main section.module-hero .section-wrapper ul li.goals a span::before {
  background-image: url("/assets/img/icons/icon-target.c36bc981.svg");
}
main section.module-hero .section-wrapper ul li.app a span::before {
  background-image: url("/assets/img/icons/icon-app.60631516.svg");
}
main section.module-hero .section-wrapper p.footnote {
  margin-top: 2rem;
}
main section.module-stories {
  position: relative;
}
main section.module-stories .section-wrapper {
  padding: 56px 32px;
}
main section.module-stories .section-wrapper h2 {
  margin-bottom: 32px;
}
main section.module-stories .section-wrapper p {
  margin-bottom: 24px;
}
main section.module-stories .section-wrapper .video-player {
  margin-bottom: 34px;
}

#slider_reg {
  left: -300px;
  width: 300px;
  top: calc(50% - 150px);
  height: 300px;
}

@media only screen and (min-width: 768px) {
  main section.module-hero {
    background-image: url("/assets/img/heros/home-hero-t.d8072978.jpg");
    background-size: 1080px;
  }
  html[data-webp=true] main section.module-hero {
    background-image: url("/assets/img/heros/home-hero-t.7ebf83c7.webp");
  }
}
@media only screen and (min-width: 768px) and (min-resolution: 192dpi) {
  main section.module-hero {
    background-image: url("/assets/img/heros/home-hero-t-2x.73b90fa1.jpg");
  }
  html[data-webp=true] main section.module-hero {
    background-image: url("/assets/img/heros/home-hero-t-2x.5970688a.webp");
  }
}
@media only screen and (min-width: 768px) {
  main section.module-hero[data-photo-note]::after {
    top: 182px;
  }
  #slider_reg {
    left: -436px;
    width: 436px;
    top: calc(50% - 130px);
    height: 260px;
  }
}
@media only screen and (min-width: 1080px) {
  main section.module-hero {
    background-image: url("/assets/img/heros/home-hero-d.2ad25d5b.jpg");
    background-size: 1920px;
  }
  html[data-webp=true] main section.module-hero {
    background-image: url("/assets/img/heros/home-hero-d.fc804700.webp");
  }
}
@media only screen and (min-width: 1080px) and (min-resolution: 192dpi) {
  main section.module-hero {
    background-image: url("/assets/img/heros/home-hero-d-2x.57905bf1.jpg");
  }
  html[data-webp=true] main section.module-hero {
    background-image: url("/assets/img/heros/home-hero-d-2x.1f054a1a.webp");
  }
}
@media only screen and (min-width: 1080px) {
  main section.module-hero .photo-note {
    top: 50px;
  }
  main section.module-hero .section-wrapper {
    padding-top: 0;
  }
  main section.module-hero .section-wrapper:first-child {
    padding-top: 250px;
  }
  main section.module-hero .section-wrapper h1 span:nth-child(1) {
    font-size: 40px;
    line-height: 1em;
  }
  main section.module-hero .section-wrapper h1 span:nth-child(2) {
    font-size: 60px;
    line-height: 1em;
  }
  main section.module-hero .section-wrapper ul {
    display: flex;
    justify-content: space-between;
    padding-top: 90px;
  }
  main section.module-hero .section-wrapper ul li {
    width: 336px;
    max-width: none;
    margin: 0;
  }
  main section.module-hero .section-wrapper ul li a {
    box-sizing: border-box;
    padding: 26px;
    transition-property: all;
    transition-duration: 250ms;
  }
  main section.module-hero .section-wrapper ul li a span::after {
    transition-property: all;
    transition-duration: 250ms;
  }
  main section.module-hero .section-wrapper ul li a:hover {
    transform: scale(105%);
  }
  main section.module-hero .section-wrapper ul li:nth-child(2) {
    margin-top: 51px;
  }
  main section.module-hero .section-wrapper p.footnote {
    margin-top: 2.5rem;
  }
  main section.module-stories .section-wrapper {
    padding: 80px 0px 72px 0;
  }
  main section.module-stories .section-wrapper h2 {
    margin-bottom: 16px;
  }
  main section.module-stories .section-wrapper p {
    margin-bottom: 48px;
  }
  main section.module-stories .section-wrapper .video-player {
    max-width: 640px;
    margin-bottom: 40px;
  }
}